import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button, P, PhoneSolidIcon, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { SplitContext } from '@splitsoftware/splitio-react'

import {
  FF_EXPT_PROBATE_QUOTE_TYPE_ENABLED,
  FLOW_PROBATE_URL,
  PROBATE_APPOINTMENT_BOOKING_URL,
} from 'config'
import HeroWithImages from 'components/HeroWithImages'
import AccoladesImage from 'components/AccoladesImage'
import QuoteButton from 'views/probate/components/QuoteButton'
import { useExperiment } from 'lib/hooks/useExperiment'
import { AWARD_LOGO_URL } from 'lib/awards/constants'

import data from './data'

const StyledSpan = styled.span`
  white-space: nowrap;
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  gap: ${GTR.M};
  flex-direction: column;

  & > div {
    margin: 0;
  }

  ${screenMin.m`
    justify-content: center;
    flex-direction: row;
  `}
`

// Hiding word 'free' below 305px to avoid
// button getting into two lines
const StyledBtnCopy = styled.span`
  @media (max-width: 305px) {
    display: none;
  }
`

interface SliceHeroProps {
  ctaText?: string
  title?: string
  subtitle?: string
}

const Hero = ({
  ctaText,
  title,
  subtitle,
}: SliceHeroProps): React.ReactElement | null => {
  // ExptProbateBooking - Clean up this component after experiment is done
  const treatment = useExperiment('ExptProbateBooking')
  const { isReady } = useContext(SplitContext)
  const isQuoteTypeExperiment =
    treatment === 'quote_flow_variant' && FF_EXPT_PROBATE_QUOTE_TYPE_ENABLED
  const isExperiment = treatment === 'experiment_variant'

  const controlVariantContent = (
    <Wrapper>
      <QuoteButton buttonText={ctaText} boldText />
    </Wrapper>
  )

  const experimentVariantContent = (
    <StyledWrapper>
      <Button.Primary
        tag="a"
        href={PROBATE_APPOINTMENT_BOOKING_URL}
        icon={<PhoneSolidIcon />}
        iconOnLeft
      >
        Book a <StyledBtnCopy>free</StyledBtnCopy> call back
      </Button.Primary>
      <QuoteButton buttonText="Get a quote online" showArrow={false} />
    </StyledWrapper>
  )

  const quoteTypeVariantContent = (
    <Wrapper>
      <QuoteButton
        buttonText="Get a free quote"
        linkPath={`${FLOW_PROBATE_URL}/type-of-quote`}
        boldText
      />
    </Wrapper>
  )

  return isReady ? (
    <Wrapper paddingFromXL={['M', 0]}>
      <HeroWithImages
        title={
          title || (
            <>
              Award-winning, <StyledSpan>low-cost</StyledSpan> probate
            </>
          )
        }
        subtitle={subtitle || data.subtitle}
        images={data.images}
        imageRightTop="20%"
        additionalContent={
          <>
            {isQuoteTypeExperiment && quoteTypeVariantContent}
            {isExperiment && experimentVariantContent}
            {!isQuoteTypeExperiment && !isExperiment && controlVariantContent}

            <Wrapper margin={['M', 0, 0]} marginFromL={['XL', 0, 0]} centered>
              <AccoladesImage
                desktopUrl={
                  AWARD_LOGO_URL.ACCOLADES_LAW_WILLS_PROBATE_WITH_SPACED_SHADOWS_2024
                }
                mobileUrl={
                  AWARD_LOGO_URL.ACCOLADES_LAW_WILLS_PROBATE_WITH_SPACED_SHADOWS_2024
                }
              />
              <Wrapper centered>
                <P size="S" margin={['S', 0, 0]} strong>
                  4 x winner Wills & Probate Firm of the Year
                </P>
                <P size="S" strong>
                  UK Enterprise Customer Care Excellence Award 2024
                </P>
              </Wrapper>
            </Wrapper>
          </>
        }
      />
    </Wrapper>
  ) : null
}

export default Hero
