import React, { useContext } from 'react'
import styled from 'styled-components'
import { Wrapper, Divider } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { SplitContext } from '@splitsoftware/splitio-react'

import {
  FF_EXPT_PROBATE_QUOTE_TYPE_ENABLED,
  FLOW_PROBATE_URL,
  PROBATE_TELEPHONE_NUMBER_EXPERIMENT,
} from 'config'
import { PRODUCTS } from 'lib/products/constants'
import { TAGS } from 'lib/trustpilot/constants'
import { useExperiment } from 'lib/hooks/useExperiment'
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'
import FourCards from 'components/FourCards'
import TrustpilotCarousel from 'components/TrustpilotWidget/TrustpilotCarousel'

import Explainer from '../../components/Explainer'
import PreferToTalk from '../../components/PreferToTalk'
import { CARDS_DATA } from '../../shared/constants'
import { useProbateFAQ } from '../../shared/queries/probateFAQ'
import Options from '../../components/Options'
import Hero from './components/Hero'

const StyledMobileDivider = styled(Divider)`
  ${screenMin.m`
    display: none;
  `}
`

interface ContentProps {
  ctaText?: string
  subtitle?: string
  title?: string
}

const Content = ({
  ctaText,
  subtitle,
  title,
}: ContentProps): React.ReactElement | null => {
  const faqSection = useProbateFAQ()
  // ExptProbateBooking - Clean up this component after experiment is done
  const treatment = useExperiment('ExptProbateBooking')
  const { isReady } = useContext(SplitContext)
  const isQuoteTypeExperiment = treatment === 'quote_flow_variant'
  const isExperiment = FF_EXPT_PROBATE_QUOTE_TYPE_ENABLED
    ? isQuoteTypeExperiment
    : treatment === 'experiment_variant'

  return isReady ? (
    <Wrapper background={COLOR.WHITE}>
      <Hero title={title} subtitle={subtitle} ctaText={ctaText} />
      <Options
        description="Whether you’re applying for probate or need support to handle the estate of someone who’s died, our specialists and legal partners are here to help."
        descriptionWidthInColumns={9}
      />
      <Explainer
        cta={{
          text: 'Get a quote in 5 minutes',
          path: FLOW_PROBATE_URL,
        }}
      />
      <Divider container margin={['S', 'auto']} marginFromL={['L', 'auto']} />
      <FourCards data={CARDS_DATA} />
      <Divider container />
      <PreferToTalk />
      <StyledMobileDivider container />
      <TrustpilotCarousel tag={TAGS.PROBATE} />
      <Divider container />
      <Wrapper container>
        <FrequentlyAskedQuestions
          title={faqSection.title}
          questions={faqSection.questions}
          selectedProduct={PRODUCTS.PROBATE}
          image="e_trim/illustrations/blob-bird-binoculars"
          showCallUsTodayCta
          ctaSubtitle="Our specialists can help you work out of you need probate, how much it costs, and the different options available."
          preventSwapping={isExperiment}
          customPhoneNumber={
            isExperiment ? PROBATE_TELEPHONE_NUMBER_EXPERIMENT : undefined
          }
        />
      </Wrapper>
    </Wrapper>
  ) : null
}

export default Content
