import { FLOW_PROBATE_URL } from 'config'

const data = {
  subtitle:
    'Take the stress out of probate, with our fixed-fee service. Talk to our team today.',
  images: {
    imageLeft: 'illustrations/square/letter-with-helper',
    imageRight: 'illustrations/turtle-house-cactus-yellow',
    mobileImage:
      'b_auto,c_fill_pad,g_auto,h_350,w_500/illustrations/letter-with-helper-probate-',
  },
  ctaLinks: [
    {
      text: 'Get a quote now',
      to: FLOW_PROBATE_URL,
      variant: 'primary',
    },
  ],
}

export default data
